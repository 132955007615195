// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-articles-js": () => import("./../../src/components/articles.js" /* webpackChunkName: "component---src-components-articles-js" */),
  "component---src-components-pages-js": () => import("./../../src/components/pages.js" /* webpackChunkName: "component---src-components-pages-js" */),
  "component---src-components-paginated-articles-js": () => import("./../../src/components/paginated/articles.js" /* webpackChunkName: "component---src-components-paginated-articles-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

